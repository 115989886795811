.page-container {
  @apply w-full max-w-screen-xl m-auto px-4 xl:px-0;
}

.small-page-container {
  @apply w-full max-w-screen-lg m-auto px-4 lg:px-0;
}

.page-container * {
  font-family: var(--primary-font-family) !important;
}
