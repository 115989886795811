.status-page-wrapper {
  max-width: 420px;
  min-height: 270px;
  border-radius: 10px;
  background: linear-gradient(348.94deg, #138BB1 23.31%, #2B357D 58.04%, #001375 96.28%);
  @apply flex-col m-auto mt-6 relative py-4;
}

.status-page-wrapper__close {
  @apply absolute right-4 top-6;
}

.status-page-wrapper__divider {
  height: 1px;
  width: 165px;
  @apply bg-secondary-300 mt-4;
}
