.home-nav {
  @apply text-white flex-col w-full items-start pt-8 text-xl z-20;
  background: linear-gradient(348.94deg, #138BB1 23.31%, #2B357D 58.04%, #001375 96.28%);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

@media (min-width: 992px) {
  .home-nav {
    @apply w-full static flex-row justify-end text-base items-center !important;
    background: transparent !important;
    height: auto;
  }
}

.dropdown-menu__item {
  border-bottom: 1px solid;
  @apply border-trueGray-900;
}
