@media (max-width: 768px) {
  .footer-logo-item img {
    max-width: 80px;
  }
}

.footer-logo-item img {
  width: 100%;
  max-width: 94px;
}

.footer-logo-item__small img {
  max-width: 70px;
}

.footer-copyright-text {
  max-width: 500px;
  @apply text-white text-center md:text-left text-sm pt-4 md:pt-0;
}

.kolba-image {
  width: 94px;
  height: 100%;
}
