.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  @apply z-50;
}

.modal-overlay .modal-content-custom {
  position: fixed;
  top: calc(50% + 20px);
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid;
  border-radius: 10px;
  outline: none;
  max-height: calc(90vh);
  box-shadow: 0 5px 10px rgba(1, 11, 68, 0.2);
  @apply z-50 bg-trueGray-600 border-blue-700 p-6;
}

.modal-overlay .modal-content-custom-overflow-visible {
  overflow: visible;
}
