.home-page-header {
  min-height: 800px;
  @apply relative;
}

.home-page-header__bg {
  max-height: 800px;
  background: url("../../../assets/header/home-header-background.jpeg") no-repeat center;
  background-size: cover;
  @apply absolute w-full h-full z-1;
}

.home-page-header__canvas-container {
  @apply absolute top-0 z-1 h-full;
}

.home-page-header__canvas {
  @apply absolute left-0 z-1;
}

.home-page-header__menu-content {
  @apply pt-4 md:pt-10 w-full max-w-screen-xl m-auto px-4 xl:px-0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.home-page-header__content {
  @apply flex items-center justify-between flex-col lg:flex-row px-2 md:px-6 pt-10 w-full m-auto;
}

.home-page-header__content h1 {
  border-bottom: 1px solid;
  @apply border-secondary-300 text-white;
}

.home-page-header__content h1:last-child {
  border: none;
}
