.suggestion-input {
  min-width: 300px;
}

.suggestion-textarea {
  border-radius: 5px;
  box-shadow: inset 0 2px 4px #CDCBD6;
  height: 200px !important;
  resize: none;
  overflow: auto !important;
  @apply bg-white p-4;
}

.suggestion-textarea:focus {
  @apply outline-1 outline outline-primary-700;
}

.suggestion-input__dark,
.suggestion-textarea__dark {
  @apply bg-trueGray-200 !important;
}
