.search-form-group {
  width: 230px;
}

@media (min-width: 580px) {
  .search-form-group {
    width: 300px;
  }
}

.search-input {
  @apply rounded-md flex-1 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.search-input__primary {
  @apply bg-secondary-200 !important;
}

.search-input__primary input {
  @apply text-blue-600;
}

.search-input__primary input::-webkit-input-placeholder { /* Edge */
  @apply text-blue-600;
}

.search-input__primary input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  @apply text-blue-600;
}

.search-input__primary input::placeholder {
  @apply text-blue-600;
}

.search-input__secondary {
  @apply bg-grey-90 !important;
}

.search-input__secondary input::-webkit-input-placeholder { /* Edge */
  @apply text-grey-80;
}

.search-input__secondary input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  @apply text-grey-80;
}

.search-input__secondary input::placeholder {
  @apply text-grey-80;
}

.search-input fieldset {
  display: none;
}

.search-button {
  width: 40px !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  @apply p-0 !important;
}

.search-button__primary {
  @apply bg-primary-700 border-primary-700 !important;
}

.search-button__secondary {
  @apply bg-secondary-300 border-secondary-300 !important;
}
