@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-font-family: 'Mardoto', sans-serif;
  --secondary-font-family: 'Roboto', sans-serif;
}

body {
  margin: 0;
  padding: 0 !important;
  font-family: var(--primary-font-family);
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*  sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-primary {
  font-family: var(--primary-font-family);
}

body * {
  font-family: var(--primary-font-family);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex-center {
  @apply flex items-center justify-center;
}

.button-primary {
  @apply py-2 px-4 bg-primary-700 text-white rounded-10 !important;
}

.button-primary:hover {
  @apply bg-secondary-300 !important;
}

.ReactModal__Body--open {
  /*overflow: hidden;*/
}

.form-input {
  border-radius: 5px;
  box-shadow: inset 0 2px 4px #CDCBD6;
  @apply bg-white;
}

.form-input .Mui-focused {
  @apply outline-1 outline outline-primary-700;
}

.form-input__invalid {
  @apply border border-solid border-red-700 !important;
}

.form-input__invalid .Mui-focused {
  @apply outline-none !important;
}

.form-input fieldset {
  display: none;
}


.main-content {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-routes-content {
  margin-top: 88px;
  display: flex;
  justify-content: center;
}

@media (min-width: 992px) and (max-width: 1440px) {
  .main-routes-content {
    margin-top: 150px;
  }
}

.main-routes-content__home {
  margin-top: 0;
}

.main-routes-content__home {
  margin-top: 0;
}

/* Custom scroll */
.custom-scroll::-webkit-scrollbar {
  width: 4px;
  height: 8px;
  background-color: #E8E8E8;
  border-radius: 10px;
  z-index: 1000;
}

.custom-scroll::-webkit-scrollbar-track {
  border-radius: 10px;
  cursor: default;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background: #3AA3B4;
  border-radius: 10px;
  cursor: default;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: #3AA3B4;
  cursor: default;
}

.custom-scroll::-webkit-scrollbar-thumb:active {
  background: #3AA3B4;
  cursor: default;
}

.MuiSelect-select {
  @apply font-medium text-primary-700 !important;
}
.MuiMenu-list {
  @apply px-3 !important;
}

.auth-banner {
  background: url("./assets/auth/register-banner.png") no-repeat center;
  background-size: cover;
  min-height: 100px;
}

.auth-banner__title {
  font-size: 30px;
  line-height: 40px;
  @apply text-secondary-300 text-center;
}

.auth-welcome__divider {
  height: 1px;
  width: 165px;
  @apply bg-secondary-300 mt-4;
}

textarea::-webkit-input-placeholder {
  color: #1D0E6D;
  font-style: italic;
}
textarea:-moz-placeholder {
  color: #1D0E6D;
  font-style: italic;
}
textarea::-moz-placeholder {
  color: #1D0E6D;
  font-style: italic;
}
textarea:-ms-input-placeholder {
  color: #1D0E6D;
  font-style: italic;
}
textarea::placeholder {
  color: #1D0E6D;
  font-style: italic;
}

