.login-form-content {
  border-radius: 10px;
  @apply bg-grey-30;
}

.login-form-banner {
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  /*background: linear-gradient(285.81deg, #138BB1 15.05%, #2B357D 69.88%, #001375 93.8%);*/
  background: radial-gradient(90.5% 7626.04% at 1.4% 84.1%, #1D0E6D 0%, #3AA3B4 100%);
  min-height: 40px;
}

.login-input {
  width: 300px;
}


