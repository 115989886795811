.footer-main {
  background: url("../../../assets/footer/footer-image.png") no-repeat center;
  background-size: cover;
  @apply text-white;
}

.footer-main-icon {
  border: 1px solid;
  @apply bg-primary-800 w-16 h-16 m-3 border-secondary-300 rounded-md;
}
